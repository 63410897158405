import FaqClientWrapper from '../components/FaqClientWrapper';
import productTypeConst from 'ptype/page/const/index';
import productConst from 'product/const/index';
const Const = { ...productTypeConst, ...productConst };

const baseUrl = process.env.BASE_PATH ? `/${process.env.BASE_PATH}/` : '/';
const mode = Const.ROUTING_MODE || 'hash';
const metaData = {
  title: Const.META_DATA.TITLE || '',
  description: Const.META_DATA.DESCRIPTION || '',
  type: process.env.TYPE || '',
};

const router = {
  base: baseUrl,
  mode: mode,
  routes: [
    {
      name: 'SingleFaqClient',
      path: '/:talkScriptId?/:stepEncoded?',
      component: FaqClientWrapper,
      meta: metaData,
    },
  ],
};

export default router;
